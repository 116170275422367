<template>
  <div class="transfer-client-owner">
    <!-- 指派 -->
    <el-dialog
      :visible.sync="transferOwnerDialogProp"
      top="10%"
      :title="$t('label.knowledgebase.assign.articles')"
      width="35%"
      :close-on-click-modal="false"
      :before-close="assignhandleClose"
    >
      <div class="selectnum" v-show="showSelectNum">
        {{ $t("vue_label_norm_recordselected", { selectNum: selectNum }) }}
        <!-- 已选择{{ selectNum }}条数据 -->
      </div>
      <div style="position: relative">
        <!-- 被分配人 -->
        <h5 style="font-weight: 600">
          <i style="color: red"> *</i>
          {{ $t("component.event.label.person") }}
        </h5>
        <el-select
          v-model="ownerType"
          filterable
          :placeholder="$t('label.select.please')"
          v-if="isQueue || isPartner"
          style="width: 120px"
        >
          <el-option
            v-for="item in ownerTypeList.filter((item, index) => {
              return (
                item.value === 'user' ||
                (item.value === 'queue' && isQueue) ||
                (item.value === 'partneruser' && isPartner)
              );
            })"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- 队列选项 -->
        <el-select
          v-model="ownerId"
          clearable
          filterable
          :placeholder="$t('label.select.please')"
          v-if="ownerType === 'queue'"
          style="display: inline-block; width: calc(100% - 120px)"
        >
          <el-option
            v-for="item in ownerQueue"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 查找/查找多选字段 -->
        <el-select
          clearable
          v-else
          v-model="ownerId"
          class="no-suffix"
          :style="{
            width: isQueue || isPartner ? 'calc(100% - 120px)' : '100%',
          }"
        >
          <p class="searchTipBox">
            <span
              @click="remoteSearchOwner"
              style="display: inline-block; width: 100%"
            >
              <i class="el-icon-search"></i>
              <span style="padding-left: 10px">
                <!-- 前往精准搜索 -->
                {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
              </span>
            </span>
          </p>
          <el-option
            v-show="ownerName !== ''"
            :key="ownerName"
            :label="ownerName"
            :value="ownerId"
          >
          </el-option>
        </el-select>
        <span class="remoteBtn" v-if="ownerType !== 'queue'">
          <i class="el-icon-search" style="margin-top: 10px"></i>
        </span>
      </div>
      <div style="margin-top: 20px">
        <!-- 指导 -->
        <h5 style="font-weight: 600">
          {{ $t("label.knowledgebase.guidance") }}
        </h5>
        <!-- 请输入内容 -->
        <el-input
          type="textarea"
          :rows="2"
          :placeholder="$t('label.tabpage.entercontent')"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <!-- 到期日期 -->
        <h5 style="font-weight: 600">
          <i style="color: red"> *</i>
          {{ $t("label.quickbooks.viewlist.duedate") }}
        </h5>
        <!-- 选择日期 -->
        <el-date-picker
          v-model="value1"
          type="date"
          value-format="yyyy-MM-dd"
          :placeholder="$t('label.tabpage.selectdate')"
        >
        </el-date-picker>
      </div>
      <div style="margin-top: 17px" v-if="ownerType !== 'queue'">
        <template>
          <el-checkbox v-model="isSendMail">
            <span style="padding-left: 6px">
              <!-- 发送电子邮件 -->
              {{ $t("page.send.email") }}
            </span>
          </el-checkbox>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="assignhandleClose" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="assignsure" size="mini" type="primary">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :ownerType="ownerType"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        :isKnowledge="isKnowledge"
        @changeSelect="changeSelectOwner"
      />
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import * as CommonObjApi from "./api.js";
import * as knowledgearticlesApi from "./api.js";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    ids: {
      type: String,
      default: "",
    },
    transferOwnerDialog: {
      type: Boolean,
      default: false,
    },
    // 对象id
    objId: {
      type: String,
      default: "",
    },
    // 是否显示统计条数
    showSelectNum: {
      type: Boolean,
      default: false,
    },
    selectNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      textarea: "",
      value1: "",
      sendMail: false,
      ownerId: "",
      ownerName: "",
      checked: false,
      fieldId: "",
      relevantObjId: "user",
      relevantPrefix: "",
      showSearchTable: false,
      checkList: [],
      isSendMail: false,
      ownerQueue: [],
      isPartner: false,
      isQueue: false,
      ownerType: "user",
      ownerTypeList: [
        {
          value: "user",
          // 用户
          label: this.$i18n.t("label.user"),
        },
        {
          value: "queue",
          // 队列
          label: this.$i18n.t("label.queue"),
        },
        {
          value: "partneruser",
          // 伙伴云用户
          label: this.$i18n.t("label.partneruser"),
        },
      ],
      isKnowledge: "true",
      emailAddress: "",
      emailName: "",
    };
  },
  mounted() {
    this.ownerId = "";
    this.ownerName = "";
    this.ownerType = "user";
    this.checkList = [];
  },
  methods: {
    //指派
    assignsure() {
      var data = [];
      if (this.id) {
        data = [
          {
            id: this.id,
            assign_toid: this.ownerId,
            assignment_due_date: this.value1,
            assignment_note: this.textarea,
          },
        ];
      } else {
        let arr = this.ids.split(",");
        arr.forEach((item) => {
          data.push({
            id: item,
            assign_toid: this.ownerId,
            assignment_due_date: this.value1,
            assignment_note: this.textarea,
          });
        });
      }

      let isSetDate = true;
      data.forEach((item) => {
        if (!item.assignment_due_date) {
          isSetDate = false;
        }
      });
      // 必填项检测
      if (!this.emailAddress || !isSetDate) {
        this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
        return;
      }

      let params = {
        data: JSON.stringify(data),
        sendEmail: this.isSendMail,
        emailAddress: this.emailAddress,
        emailName: this.emailName,
      };
      this.assignhandleClose();

      knowledgearticlesApi.designate(params).then((res) => {
        if (res.data.failCount > 0) {
          // {a}条数据指派失败
          this.$message.error(
            res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.assignment.failed", [
                res.data.failCount,
              ])
          );
        }
        if (res.data.succeedCount > 0) {
          setTimeout(() => {
            // {a}条数据指派成功
            this.$message.success(
              res.data.succeedCount +
                this.$i18n.t("label.knowledgebase.data.assignment.succeeded", [
                  res.data.succeedCount,
                ])
            );
          }, 1000);
        }
        this.$emit("refreshpage");
        this.$emit("refreshright");
        this.ownerId = "";
        this.value1 = "";
        this.textarea = "";
        this.isSendMail = false;
      });
    },
    // 查询队列数据
    queryQueue() {
      CommonObjApi.queryQueue({ id: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.ownerQueue = res.data === null ? [] : res.data;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 更改所有人是否展示伙伴云和队列
    checkQueueAndPartner() {
      CommonObjApi.checkQueueAndPartner({ id: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.isPartner = res.data.isPartner || false;
          this.isQueue = res.data.isQueue || false;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 查找所有人
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.emailAddress = row.data && row.data.email ? row.data.email : "";
      this.emailName = row.data && row.data.name ? row.data.name : "";
      this.ownerName = row.data && row.data.name ? row.data.name : "";
      this.ownerId = row.data && row.data.id ? row.data.id : "";
      this.showSearchTable = false;
    },
    assignhandleClose() {
      this.ownerId = "";
      this.ownerName = "";
      this.ownerType = "user";
      this.checkList = [];
      this.isSendMail = false;
      this.$emit("assignhandleClose");
    },
  },
  watch: {
    transferOwnerDialog(nval) {
      if (nval) {
        this.checkQueueAndPartner();
      }
    },
    ownerType(nval) {
      this.ownerId = "";
      this.ownerName = "";
      if (nval === "queue") {
        this.$nextTick(() => {
          this.queryQueue();
        });
      }
    },
  },
  computed: {
    transferOwnerDialogProp: {
      get() {
        return this.transferOwnerDialog;
      },
      set() {
        this.$emit("update:transferOwnerDialog", this.transferOwnerDialogProp);
      },
    },
  },
};
</script>

<style scoped lang="scss">
// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  display: none;
}
.remoteBtn {
  position: absolute;
  right: 8px;
  top: 29px;
  color: #c0c4cc;
  z-index: 100;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
}

::v-deep .el-input__inner {
  height: 30px;
}

.selectnum {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}

.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
</style>
