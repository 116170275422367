import axios from '@/config/httpConfig'
// 队列
export function queryQueue(data) {
    return axios.post('/objectdetail/queryQueue', data)
}
// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
}
// 指派文章接口
export function designate(data) {
    return axios.post("/knowledgeDesignate/designate", data);
}
